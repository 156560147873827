import { ItemState, RollDirection } from './ItemModel';
import { ConstValues } from '../../ConstValues';

export class Address {
  Attn!: string;
  City!: string;
  CompanyName!: string;
  Country!: Country;
  DefaultMaximumOD!: number;
  Email!: Email;
  FullAddress!: string;
  HasChanged!: boolean;
  Id!: string;
  IsBilling!: boolean;
  IsCustomerPickup!: boolean;
  IsDefault!: boolean;
  IsAResidence!: boolean;
  Options!: AddressOption;
  Phone: PhoneNumber;
  Province!: string;
  ShouldUseProvince!: boolean;
  State!: string;
  Status!: ItemState;
  Street1!: string;
  Street2!: string;
  ZipCode!: string;
  ShouldChangeAllAddresses!: boolean;
  HasAddressInstructions: boolean = false;
  AddressNote!: AddressNote;
  constructor(rawItem?: any) {
    this.HasChanged = false;
    this.Status = ItemState.New;
    if (rawItem) {
      this.FullAddress = rawItem.FullAddress;
      this.Id = rawItem.Id;
      this.CompanyName = rawItem.CompanyName;
      this.City = rawItem.City;
      this.State = rawItem.State;
      this.Province = rawItem.Province;
      this.Country = new Country(rawItem.Country);
      this.ShouldUseProvince = rawItem.ShouldUseProvince;
      this.IsDefault = rawItem.IsDefault;
      this.IsAResidence = rawItem.IsAResidence;
      this.Attn = rawItem.Attn;
      this.Street1 = rawItem.Street1;
      this.Street2 = rawItem.Street2;
      this.IsCustomerPickup = rawItem.IsCustomerPickup;
      this.ZipCode = rawItem.ZipCode;
      this.Phone = new PhoneNumber(rawItem.Phone);
      this.Options = new AddressOption(rawItem.Options);
      this.Email = new Email(rawItem.Email);
      this.IsCustomerPickup = rawItem.IsCustomerPickup;
      this.IsBilling = rawItem.IsBilling;
      this.Status = ItemState.Edit;
      this.AddressNote = rawItem.AddressNote;
      this.HasAddressInstructions = !!rawItem.AddressNote && rawItem.AddressNote.Name;
    } else {
      this.Country = new Country();
      this.Phone = new PhoneNumber();
      this.Email = new Email();
      this.Options = new AddressOption();
      this.AddressNote = new AddressNote();
    }
  }
  IsInternational() {
    return this.Country.Id > 1;
  }
  ConvertToDisplay() {
    return `<div class="roboto-bold">${this.CompanyName}</div> 
    <div>${this.Attn}</div>
            <div>${this.Street1}</div>
            <div>${this.Street2}</div>
            <div>${this.City}, ${
      this.ShouldUseProvince ? this.Province : this.State
    }</div>
            <div>
            ${this.Country?.CountryValue} ${this.ZipCode}
            </div>`;
  }
}

export class AddressChallengeResponse {
  OriginalAddress!: Address;
  EnhancedAddress!: Address;
  HasValidationErrors!: boolean;
  ValidationErrors!: string[];
  constructor(rawItem?: any) {
    if (rawItem) {
      this.EnhancedAddress = new Address(rawItem.EnhancedAddress);
      this.OriginalAddress = new Address(rawItem.OriginalAddress);
      this.HasValidationErrors = rawItem.HasValidationErrors;
      this.ValidationErrors = rawItem.ValidationErrors;
    } else {
      this.OriginalAddress = new Address();
      this.EnhancedAddress = new Address();
    }
  }
}

export class AddressNote {
  Name!: string;
  PhoneNumber!: string;
  AdditionalInstructions!: string;
  constructor(rawItem?: any) {
    if (rawItem) {
      this.Name = rawItem.Name;
      this.PhoneNumber = rawItem.PhoneNumber;
      this.AdditionalInstructions = rawItem.AdditionalInstructions;
    }
  }
}
export enum DFDCustomerOptionTypes {
  Any = 0,
  DFDOnly = 1,
  NoDFD = 2
}

export class AddressOption {
  Id!: number;
  BrandLabel!: string;
  OD!: number;
  ShipToId!: number;
  RollDirectionId!: number;
  DfdOption!: DFDCustomerOptionTypes;
  constructor(rawItem?: any) {
    if (rawItem) {
      this.BrandLabel = rawItem.BrandLabel;
      this.OD = rawItem.OD;
      this.ShipToId = rawItem.ShipToId;
      this.RollDirectionId = rawItem.RollDirectionId;
      this.DfdOption = rawItem.DFDOption;
    } else {
      this.OD = ConstValues.DEFAULT_MAX_OD;
    }
  }
}

export class ShippingVendor {
  Id!: number;
  Name!: string;
  constructor(rawItem?: any) {
    if (rawItem) {
      this.Id = rawItem.Id;
      this.Name = rawItem.Name;
    }
  }
}

export class PhoneNumber {
  Id!: string;
  PhoneNumber!: string;
  constructor(rawItem?: any) {
    this.Populate(rawItem);
  }
  Populate(rawItem: any) {
    if (rawItem) {
      this.Id = rawItem.PhoneId;
      this.PhoneNumber = rawItem.PhoneNumber;
    }
  }
}
export class Email {
  Id!: string;
  EmailAddress!: string;
  constructor(rawItem?: any) {
    this.Populate(rawItem);
  }
  Populate(rawItem: any) {
    if (rawItem) {
      this.Id = rawItem.Id;
      this.EmailAddress = rawItem.EmailAddress;
    }
  }
}

export class Country {
  Id!: number;
  CountryValue!: string;
  ShouldUseProvince!: boolean;
  constructor(rawItem?: any) {
    this.Populate(rawItem);
  }
  Populate(rawItem: any) {
    if (rawItem) {
      this.Id = rawItem.Id;
      this.CountryValue = rawItem.CountryValue;
      this.ShouldUseProvince = rawItem.ShouldUseProvince;
    } else {
      this.Id = 0;
      this.CountryValue = '';
      this.ShouldUseProvince = false;
    }
  }
}
